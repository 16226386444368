define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-nav", ["exports", "@glimmer/component", "@ember/service", "discourse/components/nav-item", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _navItem, _activityPubFollowBtn, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubNav extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get showFollows() {
      return this.args.actor.can_admin;
    }
    get showFollowers() {
      return this.site.activity_pub_publishing_enabled;
    }
    get onFollowsRoute() {
      return this.router.currentRouteName === "activityPub.actor.follows";
    }
    get showCreateFollow() {
      return this.showFollows && this.onFollowsRoute;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="activity-pub-nav">
          <ul class="nav nav-pills">
            {{#if this.showFollowers}}
              <NavItem
                @route="activityPub.actor.followers"
                @label="discourse_activity_pub.discovery.followers"
              />
            {{/if}}
            {{#if this.showFollows}}
              <NavItem
                @route="activityPub.actor.follows"
                @label="discourse_activity_pub.discovery.follows"
              />
            {{/if}}
          </ul>
          {{#if this.showCreateFollow}}
            <ActivityPubFollowBtn
              @actor={{@actor}}
              @follow={{@follow}}
              @type="actor_follow"
            />
          {{/if}}
        </div>
      
    */
    {
      "id": "6io4pqmm",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-nav\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n\"],[41,[30,0,[\"showFollowers\"]],[[[1,\"          \"],[8,[32,0],null,[[\"@route\",\"@label\"],[\"activityPub.actor.followers\",\"discourse_activity_pub.discovery.followers\"]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showFollows\"]],[[[1,\"          \"],[8,[32,0],null,[[\"@route\",\"@label\"],[\"activityPub.actor.follows\",\"discourse_activity_pub.discovery.follows\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"],[41,[30,0,[\"showCreateFollow\"]],[[[1,\"        \"],[8,[32,1],null,[[\"@actor\",\"@follow\",\"@type\"],[[30,1],[30,2],\"actor_follow\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@actor\",\"@follow\"],false,[\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-nav.js",
      "scope": () => [_navItem.default, _activityPubFollowBtn.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubNav;
});