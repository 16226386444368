define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-model", ["exports", "@glimmer/component", "@ember/object/computed", "discourse/helpers/category-link", "discourse/helpers/discourse-tag", "@ember/component", "@ember/template-factory"], function (_exports, _component, _computed, _categoryLink, _discourseTag, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubActorModel extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "isCategory", [(0, _computed.equal)("args.actor.model_type", "category")]))();
    #isCategory = (() => (dt7948.i(this, "isCategory"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "isTag", [(0, _computed.equal)("args.actor.model_type", "tag")]))();
    #isTag = (() => (dt7948.i(this, "isTag"), void 0))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="activity-pub-actor-model">
          {{#if this.isCategory}}
            {{categoryLink @actor.model}}
          {{/if}}
          {{#if this.isTag}}
            {{discourseTag @actor.model.name}}
          {{/if}}
        </div>
      
    */
    {
      "id": "lZwjtUnE",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-actor-model\"],[12],[1,\"\\n\"],[41,[30,0,[\"isCategory\"]],[[[1,\"        \"],[1,[28,[32,0],[[30,1,[\"model\"]]],null]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"isTag\"]],[[[1,\"        \"],[1,[28,[32,1],[[30,1,[\"model\",\"name\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@actor\"],false,[\"if\"]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-model.js",
      "scope": () => [_categoryLink.default, _discourseTag.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubActorModel;
});